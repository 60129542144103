import React, { useState, useRef, useEffect } from "react"
import exportFromJSON from "export-from-json";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from "reactstrap"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import withRouter from "../../components/Common/withRouter"
//i18next
import { connect } from "react-redux"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import { postSubmitForm } from "../../helpers/backend_helper"
import moment from "moment"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader";
import logo from "../../assets/images/Logo.png"
import { AvField, AvForm } from "availity-reactstrap-validation";



const ViewUsedItems = props => {

  const { SearchBar } = Search
  const [viewModal, setViewModal] = useState(false)
  const [orderedItemsArray, setOrderedItemsArray] = useState({})
  const [selectedOrder, setSelectedOrder] = useState([]);

  const handleDownload = async () => {
    const fileName = "All used Items";
    const exportType = "xls";

    let data_to_export = allOrder;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Name: v.name,
          Section: v.section,
          Formula: v.formula > 0 ? "1" + v.unit + "=" + v.formula + v.sub_unit : " N/A",
          Quantity: `${v.quantity.in_unit} ${v.unit} ${v.sub_unit ? v.quantity.in_sub_unit : ""} ${v.sub_unit ? v.sub_unit : ""}`,
          Price: v.order_price,
          Branch: v.branch_details?.name,
          Used_By: v.order_created_by,
          Date: moment(v.createdAt).format("YYYY-MM-DD"),
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }
  useEffect(() => {
    handleValidSubmit();
  }, [])


  //getAll
  const [allOrder, setAll_Order] = useState([])
  const loadAllOrder = async (object) => {
    try {
      preloader(true)
      let dateFrom = moment(object.from_date);
      let dateTo = moment(object.to_date);
      let totalDays = dateTo.diff(dateFrom, 'days') + 1;
  
      if(totalDays>31){
          preloader(false)
        showWarning("Total number of days cannot be more than 31.", "Warning");
        return ;
      }
      let url = process.env.REACT_APP_BASEURL + "internal_orders/getall"
      let response = await postSubmitForm(url, object)
      if (response.status === 1) {
        preloader(false)
        setAll_Order(response.data)
      } else {
        preloader(false)
        showNotification(response.message, "Error")
      }
    } catch (error) {
      preloader(false)
      showNotification(error?.message, "Error")
    }
  }

  const [fromdate, setFromdate] = useState(new Date())
  const [todate, setTodate] = useState(new Date())
  const handleValidSubmit = async () => {
    const object = {
      from_date: moment(fromdate).format("YYYY-MM-DD"),
      to_date: moment(todate).format("YYYY-MM-DD"),
    }
    loadAllOrder(object);
  }
  //for round image view
  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url || row.image + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        >
        </span>
      </>
    )
  }

  const ItemDetails = [
    {
      dataField: "_id",
      hidden: true
    },
    {
      text: props.t("S.N"),
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },

      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image_url",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Unit"),
      dataField: "quantity.in_unit",
      sort: false,
      formatter: (cell, row) => {
        return row.quantity.in_unit + " " + row.unit
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Sub-Unit"),
      dataField: "quantity.in_sub_unit",
      sort: false,
      formatter: (cell, row) => {
        return row.quantity.in_sub_unit + " " + row.sub_unit
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

  ]

  const allOrdersColumns = [

    {
      dataField: "_id",
      hidden: true
    },
    {
      text: props.t("#"),
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },

      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Section"),
      dataField: "section",
      filter: textFilter({
        caseSensitive: false,
        style: {
          height: "20px",
        }
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Formula"),
      dataField: "formula",
      sort: false,
      formatter: (cell, row) => {
        if (row.formula > 0) {
          return (
            <>
              {"1 " + row.unit + " = " + row.formula + " " + row.sub_unit}
            </>
          )
        }

      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },


    {
      dataField: "quantity.in_unit",
      text: "Quantity",
      sort:true,
      formatter: (cell, row) => {
        return (
          <>
            {row.quantity?.in_unit +
              "  " +
              row.unit +
              " " +
              (row.sub_unit && row.sub_unit != "undefined" && row.quantity.in_sub_unit > 0
                ? row.quantity.in_sub_unit + " " + row.sub_unit
                : "")}
          </>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      }
    },
    {
      text: props.t("Total Price(QR)"),
      dataField: "total_price",
      formatter: (cell, row) => { 
        const formattedTotalPrice = row.total_price.toFixed(2);
        return Number(formattedTotalPrice);
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Used By"),
      dataField: "order_created_by",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Date"),
      dataField: "createdAt",
      sort: true,
      formatter: (cell, row) => {
        const timestamp = row.createdAt;
        const formattedDate = moment(timestamp).format('YYYY-MM-DD');
        return formattedDate;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
  ]


  const defaultSorted = [{
    dataField: 'createdAt',
    order: 'desc'
  }];

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",


  };


  const printDiv = (divName) => {
    let content = document.getElementById(divName);
    var printWindow = window.open("",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0");
    const imageHeight = "105px";
    const imageWidth = "110px";
    const borderradius = "50%";
    const background = "#000000";
    printWindow.document.write(`
    
        <html>
          <title> Rendezvous - Game Parlour </title>
        <head> 
          <style>
          table {
            table-layout: fixed;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 100%;
            color: #495057;
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 0 0;
            font-weight: bold;
          }

          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 0 0;
            font-weight: normal !important;
          }
          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
          .head{
            display:flex;
            justify-content:space-between;
          }
          .hide{
              display:none;
          }
          .react-bootstrap-table-pagination {
              display: none; 
          }
          .heading{
              display: flex;
              align-items: center;
          }
          h2 {
              margin: 0; 
          }
          #logoImg {
              height: ${imageHeight};
              width: ${imageWidth};
              background-color: ${background};
              border-radius: ${borderradius};
              margin-right: 140px; 
          }
          </style>
        </head>
      <body>
    <div class="heading">

    <img src=${logo} id="logoImg" alt="Logo" >
    <h2>  Used Items</h2>
    </div>
    ${content.innerHTML}
      </body>
        </html>

      `);
    printWindow.document.close();
    const logoImg = printWindow.document.getElementById('logoImg');
    logoImg.onload = function () {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Internal Uses" breadcrumbItem1="View Used Items" alternateTitle="View Used Items" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody style={{
                  background:"linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                  boxShadow:"rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}>
                  <AvForm onValidSubmit={handleValidSubmit} >
                    <Row >
                      <Col lg={3}>
                        <label className="form-label text-white">
                          From Date
                        </label>

                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={fromdate}
                          options={{
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener('mousedown', (e) => {
                                e.preventDefault(); // Prevent default click behavior
                              });
                            },
                          }}
                          onChange={(v) => {
                            const dateString = v;
                            setFromdate(new Date(dateString))
                          }}
                          name="from_date"
                          placeholder="Select From Date"
                          errorMessage="Select From Date."
                          validate={{
                            required: { value: true },
                          }}
                        />

                      </Col>

                      <Col lg={3}>

                        <label className="form-label text-white">
                          To Date
                        </label>
                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={todate}
                          onChange={(v) => {
                            const dateString = v;
                            setTodate(new Date(dateString))
                          }}
                          options={{
                            minDate: "today",
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener('mousedown', (e) => {
                                e.preventDefault(); // Prevent default click behavior
                              });
                            },

                          }}

                          name="to_date"
                          placeholder="Select To Date"
                          errorMessage="Select To Date."
                          validate={{
                            required: { value: true },
                          }}
                        />


                      </Col>

                      <Col
                        lg={2}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label
                          style={{ height: "0px" }}
                          className="form-label"
                        ></label>
                        <Button
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "#fdd005",
                            border: "none",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "500",
                            width: "128px",
                            marginTop: "1rem",
                            // margin: "auto",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }}
                          type="submit"
                          // color="primary"
                          // className="btn btn-info btn-md"
                          onMouseEnter={e => {
                            e.target.style.transform = "scale(1.05)"
                          }}
                          onMouseLeave={e => {
                            e.target.style.transform = "scale(1)"
                          }}
                        >
                          Show
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              background:
                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                              pointerEvents: "none",
                            }}
                          ></div>
                        </Button>
                      </Col>

                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row >
            <Col>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >

                  <div id="printDiv">
                    <ToolkitProvider
                      keyField="_id"
                      data={allOrder || []}
                      columns={allOrdersColumns}
                      search
                    >
                      {propst => (
                        <div>
                          <Row className="hide">
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar
                                className="form-control"
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                                placeholder="search"
                              />
                            </Col>
                            <Col lg={8} style={{ display: "flex", justifyContent: "flex-end" }}>

                              <Button
                                style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                className="btn btn-sm btn-secondary"
                                onClick={() => { printDiv("printDiv") }}
                                disabled={allOrder?.length <= 0}
                              >
                                <i
                                  className="fa fa-print " aria-hidden="true"
                                  onClick={() => { printDiv("printDiv") }}
                                />&nbsp; {props.t("Print")}
                              </Button>
                              <Button
                                style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                type="submit"
                                className="btn btn-sm btn-secondary"
                                onClick={handleDownload}
                                disabled={allOrder?.length <= 0}
                              >
                                <i className="mdi mdi-file-export" /> &nbsp;
                                {props.t("Export CSV")}
                              </Button>
                            </Col>

                          </Row>

                          <BootstrapTable
                            {...propst.baseProps}
                            noDataIndication={props.t("No data to display.")}
                            striped
                            hover
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            condensed
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Row>
        <Col>
          <Modal
            size="xl"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
          >
            <ModalHeader toggle={() => setViewModal(!viewModal)} >
              {props.t("Withdraw Details")} {selectedOrder && selectedOrder.po_id}
            </ModalHeader>
            <ModalBody>
              {orderedItemsArray && (
                <Card>
                  <CardBody>
                    <CardTitle>{props.t("Used Items")} </CardTitle>
                    <ToolkitProvider
                      keyField="item_id"
                      data={selectedOrder}
                      columns={ItemDetails}
                    >
                      {propst => (
                        <div>
                          <BootstrapTable
                            {...propst.baseProps}
                            // cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                            noDataIndication={props.t("No data to display.")}
                            striped
                            filter={filterFactory()}
                            hover
                            condensed
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              )}
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(ViewUsedItems)))
