import React, {  useState, useRef } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  Table,
  Badge,
  Spinner,
} from "reactstrap"
import BackgroundImage from "../../assets/images/Background.png"

import preloader from "../../helpers/Loader"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json"
import { formatDate } from "@fullcalendar/core"
import { postSubmitForm } from "../../helpers/backend_helper"

const GenerateSalary = props => {
  const refContainer = useRef(null)
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const [salaryData, setSalaryData] = useState([])
  const [salaryTotalStats, setSalaryTotalStats] = useState({ remark: "" })
  const [generateBtnDisabled, setGenerateBtnDisabled] = useState(true)
  const [year, setYear] = useState("")
  const [excel_data, setExcel_data] = useState({})
  const [isLoading, setIsLoading] = useState(false)


  //Export to csv
  const handleDownload = async () => {
    const fileName = "Salary Details"
    const exportType = "xls"

    let data_to_export = []
    data_to_export.push(excel_data)

    // console.log(data_to_export)

    if (data_to_export.length > 0) {
      const data = []
      data_to_export.forEach(v => {
        v.employee_details.forEach(employee => {
          const rowData = {
            // 'Employee Id': employee.employee_id,
            "Employee Code": employee.employee_code,
            "Employee Name": employee.employee_name,
            "Date of Birth": formatDate(employee.date_of_birth),
            "Date of Joining": formatDate(employee.date_of_joining),
            Designation: employee.designation,
            "Number of Days": employee.number_of_days,
            "Basic Salary": employee.basic_salary,
            "Allowance Food": employee.allowance.food,
            "Allowance Housing": employee.allowance.housing,
            "Allowance Other": employee.allowance.other,
            "Allowance Overtime": employee.allowance.overtime,
            "Allowance Arrear": employee.allowance.arrear,
            "Allowance Special": employee.allowance.special,
            "Allowance Loan Arrear": employee.allowance.loan_arrear,
            "Deduction Loan": employee.deduction.loan,
            "Deduction Undertime": employee.deduction.undertime,
            "Deduction Punishment": employee.deduction.punishment,
            "Net Salary": employee.net_salary,
            "Net Salary R.O.": employee.net_salary_round_off,
          }
          data.push(rowData)
        })
      })

      exportFromJSON({ data, fileName, exportType })
    }
  }
  

  const getGenerateSalary = async (payload) => {
    try {    
      setIsLoading(true)
      // preloader(true)
     let url = process.env.REACT_APP_BASEURL + "generatesalaries/generate_salary"
     let response = await postSubmitForm(url, payload)
     if (response.status === 1) {
      // refContainer.current.reset()
      setGenerateBtnDisabled(response.data.is_salary_generated ? true : false)
      showNotification(response.message,response.status === 0 ? "Error" : "Success")
      localStorage.setItem("salaryGeneratedDate",response.data.date.slice(0, 10))
     } else {
       showNotification(response.message, "Error")
     }
    } catch (error) {
     console.log(error)
    } finally {
      setIsLoading(false)
      // preloader(false)
    }
   }

  // function to get the user Input for salary generation table of all employees
  const handleValidSubmit = async (e, v, token) => {
    const object = {
      date: v.month + "-01",
    }
    setIsLoading(true)
    getGenerateSalary(object, token)
  }

  // function to get month/date input from the user

  const handleShowSalaries = async (e, token) => {
    try {
      preloader(true); // Using `setIsLoading` for preloader  
      // Calculate the previous month's date
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1);
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-01`;
  
      setYear(year);
  
      const selectedDate = `${e.target.value}-01`;
  
      if (selectedDate === formattedDate || Number(selectedDate.slice(5, 7)) < Number(month)) {
        const payload = {
          date: selectedDate,
          source: "get_salaries",
        };
        let url = process.env.REACT_APP_BASEURL + "generatesalaries/generate_salary"
        const response = await postSubmitForm(url,payload);

        if (response && response.status === 1) {
          const { data } = response;
          setGenerateBtnDisabled(data.is_salary_generated)
          setSalaryData(data.employee_details);
          setSalaryTotalStats({
            ...salaryTotalStats,
            monthlyTotal: data.monthly_total,
            monthlyTotalRoundOff: data.monthly_total_round_off,
            totalAllowance: data.total_allowance,
            totalDeduction: data.total_deduction,
            totalBasicSalary: data.total_basic_salary,
            remark: data.remark,
          });
          setExcel_data(data);
        }
      } else {
        showNotification(_, "Salary data not present yet.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      preloader(false); 
    }
  };

  // const handleShowSalaries = async (e, token)=> {
  // try {
    
  //   preloader(true)
  //   let url = process.env.REACT_APP_BASEURL

  //   // getting previous months date
  //   var currentDate = new Date()
  //   currentDate.setMonth(currentDate.getMonth() - 1)
  //   var year = currentDate.getFullYear()
  //   var month = (currentDate.getMonth() + 1).toString().padStart(2, "0")

  //   var formattedDate = year + "-" + month + "-" + "01"
  //   setYear(year)
  //   if (e.target.value + "-01" === formattedDate) {
  //     const payload = {
  //       date: e.target.value + "-01",
  //       source: "get_salaries",
  //     }

  //     axios
  //       .post(
  //         url + `generatesalaries/generate_salary`,
  //         payload,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then(res => {
  //         preloader(false)
  //         console.log(res.data.data);
  //         setSalaryData(res.data.data.employee_details)
  //         setSalaryTotalStats({
  //           ...salaryTotalStats,
  //           monthlyTotal: res.data.data.monthly_total,
  //           monthlyTotalRoundOff: res.data.data.monthly_total_round_off,
  //           totalAllowance: res.data.data.total_allowance,
  //           totalDeduction: res.data.data.total_deduction,
  //           totalBasicSalary: res.data.data.total_basic_salary,
  //           remark: res.data.data.remark,
  //         })
  //         setExcel_data(res.data.data)
  //         setGenerateBtnDisabled(res.data.data.is_salary_generated ? true : false)
  //       })
  //       .catch(err => {
  //         console.log(err)
  //         preloader(false)
  //       })
  //   } else if (Number(e.target.value.slice(5, 8)) < Number(month)) {
  //     // console.log("salary already generated ")
  //     // setGenerateBtnDisabled(true)

  //     const payload = {
  //       date: e.target.value + "-01",
  //       source: "get_salaries",
  //     }

  //     axios
  //       .post(
  //         url + `generatesalaries/generate_salary`,
  //         payload,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then(res => {
  //         // console.log(res.data.data);
  //         preloader(false)
  //         setSalaryData(res.data.data.employee_details)
  //         setExcel_data(res.data.data)
  //         setGenerateBtnDisabled(res.data.data.is_salary_generated ? true : false)
  //         setSalaryTotalStats({
  //           ...salaryTotalStats,
  //           monthlyTotal: res.data.data.monthly_total,
  //           monthlyTotalRoundOff: res.data.data.monthly_total_round_off,
  //           totalAllowance: res.data.data.total_allowance,
  //           totalDeduction: res.data.data.total_deduction,
  //           totalBasicSalary: res.data.data.total_basic_salary,
  //           remark: res.data.data.remark,
  //         })
  //       })
  //       .catch(err => console.log(err))
  //   } else {
  //     preloader(false)
  //     showNotification(_, "Salary data not present yet.")
  //   }
  // } catch (error) {
  //   console.log(error);
  // } finally {
  //  preloader(false)
  // }
  // }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  // ---------------------------------------------------------------------
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t(" Salary")}
            breadcrumbItem1={props.t("Generate Salary")}
            alternateTitle={props.t("Generate Salary")}
          />

          <Row>
            <Col lg={7}>
              <Card style={{ height: "120px" }}>
                <CardBody>
                  <Row
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col>
                      <h6> Total Basic Salary</h6>
                      <Badge className="fs-5  badge-soft-success m-2">
                        {salaryTotalStats.totalBasicSalary || 0}
                      </Badge>
                    </Col>
                    <Col>
                      <h6>Total Allowance</h6>
                      <Badge className="fs-5  badge-soft-success m-2">
                        {salaryTotalStats.totalAllowance || 0}
                      </Badge>
                    </Col>
                    <Col>
                      <h6> Total Deduction</h6>
                      <Badge className="fs-5  badge-soft-success m-2">
                        {salaryTotalStats.totalDeduction || 0}
                      </Badge>
                    </Col>
                    <Col>
                      <h6>Total Monthly Salary</h6>
                      <Badge className="fs-5  badge-soft-success m-2">
                        {salaryTotalStats.monthlyTotal || 0}
                      </Badge>
                    </Col>
                    <Col>
                      <h6>Total Monthly R.O.</h6>
                      <Badge className="fs-5  badge-soft-success m-2">
                        {salaryTotalStats.monthlyTotalRoundOff || 0}
                      </Badge>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={5}>
              <Card style={{ height: "" }}>
                <CardBody>
                  {localStorage.getItem("role") === "Owner" && (
                    <AvForm
                      onValidSubmit={(e, v) =>
                        handleValidSubmit(e, v, employeeToken)
                      }
                      ref={refContainer}
                    >
                      {/* <Row style={{ display: 'flex', justifyContent: "space-around", alignItems: "center" }} > */}

                      <Row>
                        <Col lg={4} md={4} sm={4}>
                          <AvField
                            className="form-control"
                            label="Select Month"
                            type="month"
                            name="month"
                            placeholder="select month"
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Select Month")}
                            onChange={e => handleShowSalaries(e, employeeToken)}
                          />
                        </Col>
                        <Col lg={4} md={4} sm={4}>
                          <AvField
                            label={props.t("Select Branch")}
                            className="form-control"
                            type="select"
                            name="branch"
                            errorMessage="Please Select Branch"
                            validate={{
                              required: { value: false },
                            }}
                          >
                            <option value="">{props.t("All")}</option>
                            <option value="Rendezvous">
                              {props.t("Rendezvous")}
                            </option>
                          </AvField>
                        </Col>
                        <Col lg={4} md={4} sm={4}>
                          {/* <Button disabled={generateBtnDisabled} type="submit" style={{ width: "100px", marginTop:"1.65rem" }}  >
                                                {props.t("Generate")}
                                            </Button> */}
                          <Button
                            disabled={generateBtnDisabled}
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "500",
                              width: "100px",
                              marginTop: "1.588rem",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            {isLoading ? <Spinner size="sm"/> : props.t("Generate")}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  )}

                  {localStorage.getItem("role") !== "Owner" && (
                    <AvForm
                      onValidSubmit={(e, v) =>
                        handleValidSubmit(e, v, employeeToken)
                      }
                      ref={refContainer}
                    >

                      <Row>
                        <Col lg={6}>
                          <AvField
                            className="form-control"
                            label="Select Month"
                            type="month"
                            name="month"
                            placeholder="select month"
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Select Month")}
                            onChange={e => handleShowSalaries(e, employeeToken)}
                          />
                        </Col>
                       
                        <Col lg={6}>
                          <Button
                            disabled={generateBtnDisabled}
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "500",
                              width: "150px",
                              marginTop: "1.588rem",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            {isLoading ? <Spinner size="sm"/> : props.t("Generate")}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col>
                        <h2
                          style={{ textAlign: "center" }}
                        >{`${salaryTotalStats.remark} ${year}`}</h2>
                      </Col>

                    </Row>
                  </CardTitle>
                  <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Col
                      lg={2}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        style={{
                          width: "115px",
                          height: "35px",
                          marginTop: "-0.9rem",
                          background: "#F93A5C",
                          border: "none",
                        }}
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                        disabled={salaryData.length === 0}
                      >
                        <i className="mdi mdi-file-export" /> &nbsp;
                        {props.t("Export CSV")}
                      </Button>
                      <hr />
                    </Col>
                  </Row>

                  <div style={{ overflowX: "auto", width: "auto" }}>
                    <Table border="1px">
                      <thead>
                        <tr style={{ textAlign: "center" }}>
                          <th>#</th>
                          <th>Employee Code</th>
                          <th>Employee Name</th>
                          <th>No. of Days</th>
                          <th style={{ textAlign: "center" }}>
                            Basic Salary{"(QAR)"}
                          </th>
                          <th colSpan="7" style={{ textAlign: "center" }}>
                            Allowance(QAR)
                          </th>
                          {/* <th>...</th>
                                                    <th>...</th>
                                                    <th>...</th>
                                                    <th>...</th>
                                                    <th>...</th> */}
                          <th colSpan="3" style={{ textAlign: "center" }}>
                            Deduction(QAR)
                          </th>

                          <th>Net Salary{"(QAR)"}</th>
                          <th>Net Salary R.O. {"(QAR)"}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr style={{ fontWeight: "bold", textAlign: "center" }}>
                          <td></td>
                          <td
                            style={{
                              borderLeft: "1px solid lightGray",
                              borderRight: "1px solid lightGray",
                            }}
                          ></td>
                          <td
                            style={{
                              borderLeft: "1px solid lightGray",
                              borderRight: "1px solid lightGray",
                            }}
                          ></td>
                          <td
                            style={{
                              borderLeft: "1px solid lightGray",
                              borderRight: "1px solid lightGray",
                            }}
                          ></td>
                          <td
                            style={{
                              borderLeft: "1px solid lightGray",
                              borderRight: "1px solid lightGray",
                            }}
                          ></td>
                          <td style={{ borderLeft: "1px solid lightGray" }}>
                            Food
                          </td>
                          <td>Housing</td>
                          <td>Other</td>
                          <td>Overtime</td>
                          <td>Arrear</td>
                          <td>Special</td>
                          <td style={{ borderRight: "1px solid lightGray" }}>
                            Loan Arrear
                          </td>
                          <td>Loan</td>
                          <td>Undertime</td>
                          <td>Punishment</td>
                          <td
                            style={{
                              borderLeft: "1px solid lightGray",
                              borderRight: "1px solid lightGray",
                            }}
                          ></td>
                        </tr>
                        {salaryData.map((emp, index) => (
                          <tr key={index} style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td
                              style={{
                                borderLeft: "1px solid lightGray",
                                borderRight: "1px solid lightGray",
                              }}
                            >
                              {emp.employee_code}
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid lightGray",
                                borderRight: "1px solid lightGray",
                              }}
                            >
                              {emp.employee_name}
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid lightGray",
                                borderRight: "1px solid lightGray",
                              }}
                            >
                              {" "}
                              {emp.number_of_days}
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid lightGray",
                                borderRight: "1px solid lightGray",
                              }}
                            >
                              {emp.basic_salary}
                            </td>
                            <td>{emp.allowance.food}</td>
                            <td>{emp.allowance.housing}</td>
                            <td>{emp.allowance.other}</td>
                            <td>{emp.allowance.overtime || 0}</td>
                            <td>{emp.allowance.arrear}</td>
                            <td>{emp.allowance.special}</td>
                            <td style={{ borderRight: "1px solid lightGray" }}>
                              {emp.allowance.loan_arrear}
                            </td>
                            <td>{emp.deduction.loan}</td>
                            <td>{emp.deduction.undertime}</td>
                            <td>{emp.deduction.punishment || 0}</td>
                            <td
                              style={{
                                borderLeft: "1px solid lightGray",
                                fontWeight: "bold",
                              }}
                            >
                              {emp.net_salary}
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid lightGray",
                                fontWeight: "bold",
                              }}
                            >
                              {emp.net_salary_round_off}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(GenerateSalary)))
